<template>
  <div>
    <a-modal
          :visible="visible.modalAddUnit"
          title="Buat Unit Baru"
          okText="Buat"
          @ok="handleCreateUnit"
          @cancel="handleCancel"
          :okButtonProps="{
          props: {
              disabled: loading.add,
          },
          }"
          :cancelButtonProps="{
          props: {
              disabled: loading.add,
          },
          }"
      >
        <div
        v-if="loading.add"
            style="
            display: flex;
            justify-content: center;
            height: 30vh;
            align-items: center;
            "
        >
        <a-spin>
            <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
            />
        </a-spin>
        </div>
        <a-form
        v-else
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
        >
        <a-form-item label="Nama">
            <a-input
            :value="this.unitName"
            placeholder="Nama Unit"
            @change="(e) => handleAddUnitChange(e.target.value)"
            />
        </a-form-item>
        </a-form>
    </a-modal>
    <a-modal
        :visible="visible.modalEditUnit"
        :confirm-loading="loading.confirm"
        @ok="handleOkEditUnit"
        @cancel="handleCancel"
        :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
        }"
        okText="Perbaharui"
        :okButtonProps="{
        props: {
            shape: 'round',
        },
        }"
        :cancelButtonProps="{
        props: {
            shape: 'round',
        },
        }"
    >
        <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> KONFIRMASI EDIT UNIT
        </h3>
        <p>
          Apakah anda yakin mau memperbarui nama unit ini?
          Setelah diperbarui, data lama unit ini akan digantikan dengan data yang baru.
        </p>
    </a-modal>
    <div class="d-flex align-items center">
    <div class="mr-3">
        <a-input v-model="unitSearch" @keyup="searchUnit" size="large" :placeholder="`Cari Nama Unit...`" />
    </div>
    <div class="ml-auto">
        <template v-if="!edit.unit">
        <a-button class="mr-3" size="large" type="primary" @click.prevent="showModalAddUnit(true)" icon="plus">Tambah</a-button>
        <a-button size="large" @click.prevent="toEditUnit" icon="edit">Ubah</a-button>
        </template>
        <template v-else>
        <a-button
            @click.prevent="cancelEdit"
            :loading="loading.cancel"
            type="danger"
            size="large"
            icon="close"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >Batal</a-button
        >
        <a-button
            size="large"
            @click.prevent="saveEdit"
            icon="save"
            style="background-color: #41b883; color: white"
            >Simpan</a-button
        >
        </template>
    </div>
    </div>
    <div class="mt-4">
    <a-table :columns="unitColumns" :data-source="unitDataTable" :loading="loading.table" :pagination="pagination" @change="handleTableChangeUnit" bordered>
        <div
        v-if="edit.unit"
        slot="nama"
        slot-scope="text, record"
        >
        <a-input
            :value="text"
            style="height: 50px; width: 400px"
            @change="
            (e) => handleEditUnitChange(e.target.value, record.key, 'nama')
            "
        />
        </div>
        <div v-else slot="nama" slot-scope="text">
        <span>{{ text }}</span>
        </div>
        <div slot="aksi" slot-scope="text, record">
        <a-button :disabled="edit.unit" size="large" class="text-danger border border-danger" :value="text" :loading="loading.delete" @click.prevent="(e) => deleteUnit(e.target.value, record.key)"><a-icon type="delete" />Hapus</a-button>
        </div>
    </a-table>
    </div>
  </div>
</template>

<script>
const unitColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    scopedSlots: { customRender: 'no' },
    width: '8%',
    align: 'center',
    sorter: true,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    align: 'center',
    sorter: true,
    width: '60%',
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]

export default {
  name: 'master-unit',
  data() {
    return {
      unitColumns,
      unitDataTable: [],
      savedUnitData: [],
      visible: {
        modalAddUnit: false,
        modalEditUnit: false,
      },
      loading: {
        table: false,
        add: false,
        delete: false,
        cancel: false,
        confirm: false,
      },
      pagination: {},
      unitPage: 'all',
      unitSearch: '',
      unitOrder: '',
      unitSortBy: '',
      unitName: null,
      edit: {
        unit: false,
      },
    }
  },
  methods: {
    handleEditUnitChange(value, key, column) {
      const newData = [...this.unitDataTable]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedUnitData = newData
      }
    },
    handleTableChangeUnit(pagination, filters, sorter) {
      // console.log('sorter', sorter)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'nama') {
        sortBy = 'nama'
      } else {
        sortBy = ''
      }
      this.fetchDataUnit({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.add = boolean
      this.loading.delete = boolean
      this.loading.cancel = boolean
    },
    showModalAddUnit(boolean) {
      this.visible.modalAddUnit = boolean
    },
    searchUnit() {
      if (this.unitSearch.length > 2) {
        this.fetchDataUnit({
          search: this.unitSearch,
        })
      } else {
        this.fetchDataUnit({
          search: this.unitSearch,
        })
      }
    },
    fetchDataUnit(params = { order: this.unitOrder, sortBy: this.unitSortBy, search: this.unitSearch, page: this.unitPage }) {
      this.handleAllLoading(true)
      this.$store.dispatch('adminHR/GET_UNIT_BY_SITUATION', {
        order: params.order,
        sortBy: params.sortBy,
        search: params.search,
        page: params.page,
      })
        .then(res => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          let no = 1
          this.unitDataTable = res.data.map(row => {
            const name = row.nama
            return {
              key: row.id,
              no: no++,
              nama: name.toUpperCase(),
            }
          })
          this.savedUnitData = this.unitDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalAddUnit = false
      this.visible.modalEditUnit = false
    },
    handleAddUnitChange(value) {
      this.unitName = value
    },
    handleCreateUnit(value) {
      if (this.unitName === '' || this.unitName === null) {
        this.$notification.error({
          message: 'Error',
          description: 'Harap mengisi nama unit! Nama Unit tidak boleh kosong!',
        })
        this.visible.modalAddUnit = false
      } else {
        this.handleAllLoading(true)
        this.$store
          .dispatch('adminHR/CREATE_UNIT', {
            data: {
              nama: this.unitName,
            },
          })
          .then(
            (
              params = {
                page: 'all',
                order: 'ASC',
                sortBy: '',
                search: '',
              },
            ) => {
              return this.$store.dispatch('adminHR/GET_UNIT_BY_SITUATION', {
                order: params.order,
                sortBy: params.sortBy,
                search: params.search,
                page: params.page,
              })
                .then(res => {
                  // console.log('res', res)
                  const pagination = { ...this.pagination }
                  pagination.total = res.total
                  this.pagination = pagination
                  let no = 1
                  this.unitDataTable = res.data.map(row => {
                    const name = row.nama
                    return {
                      key: row.id,
                      no: no++,
                      nama: name.toUpperCase(),
                    }
                  })
                  this.savedUnitData = this.unitDataTable
                  this.handleAllLoading(false)
                  this.visible.modalAddUnit = false
                  setTimeout(() => {
                    this.$notification.success({
                      message: 'Sukses',
                      description: `Berhasil menambah unit baru ${this.unitName}`,
                    })
                  }, 1000)
                })
            },
          )
      }
    },
    deleteUnit(value, key) {
      this.handleAllLoading(true)
      this.$confirm({
        title: 'Peringatan!!',
        content: (
          <div>Apakah anda yakin ingin menghaspus unit ini? Setelah menghapus, data unit ini akan hilang!</div>
        ),
        onOk: () => {
          this.$store.dispatch('adminHR/DELETE_UNIT', {
            id: key,
          })
            .then(res => {
              this.handleAllLoading(false)
              if (res === 200) {
                this.$notification.success({
                  message: 'Sukses',
                  description:
                    'Unit berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Unit tidak berhasil dihapus',
                })
              }
              this.fetchDataUnit()
            })
        },
        onCancel: () => {
          this.handleAllLoading(false)
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    toEditUnit() {
      this.edit.unit = true
    },
    saveEdit() {
      this.visible.modalEditUnit = true
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataUnit()
      setTimeout(() => {
        this.edit.unit = false
        this.handleAllLoading(false)
      })
    },
    validateUnitDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i])
        if (data[i].nama === '' || data[i].nama === '-') {
          isInvalid = true
          message = `Maaf nama unit, ${data[i].nama} tidak boleh kosong!`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkEditUnit() {
      const newData = [...this.savedUnitData]
      if (newData.length) {
        const validation = this.validateUnitDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.visible.modalEditUnit = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              nama: row.nama,
            }
          })
          // console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store
            .dispatch('adminHR/UPDATE_UNIT', {
              data: target,
            })
            .then(res => {
              setTimeout(() => {
                this.fetchDataUnit()
                this.edit.unit = false
                this.visible.modalEditUnit = false
                this.handleAllLoading(false)
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Sukses',
                    description: 'Unit berhasil diperbarui',
                  })
                } else {
                  this.$notification.error({
                    message: 'Gagal',
                    description: 'Unit tidak berhasil diperbarui',
                  }, 1000)
                }
              })
            })
        }
      } else {
        this.edit.unit = false
        this.visible.modalEditUnit = false
        this.handleAllLoading(false)
        setTimeout(() => {
          this.$notification.error({
            message: 'Gagal',
            description: 'Unit tidak berhasil diperbarui',
          })
        })
      }
    },
  },
  mounted() {
    this.fetchDataUnit()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black
}
</style>
